<template>
  <div
    class="wrap__content"
    v-bind:class="{ 'wrap__content--placeholder': !user }"
  >
    <div class="page__placeholder" v-if="!user">
      <div class="form__block--placeholder">
        <div class="form__placeholder--circle">
          <div class="placeholder__circle">
            <svg
              width="73"
              height="73"
              viewBox="0 0 73 73"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="36.5"
                cy="36"
                r="24"
                stroke="#FFF7E1"
                stroke-width="2"
              />
              <g filter="url(#filter0_d_22_5658)">
                <path
                  d="M16.1604 23C13.5332 26.8866 12 31.5673 12 36.6045C12 50.0778 22.969 61 36.5 61C50.031 61 61 50.0778 61 36.6045C61 34.8685 60.8179 33.1748 60.4716 31.5413"
                  stroke="#FFCC47"
                  stroke-width="4"
                  stroke-linecap="round"
                />
              </g>
              <defs>
                <filter
                  id="filter0_d_22_5658"
                  x="5"
                  y="15.9998"
                  width="63"
                  height="52.0002"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset />
                  <feGaussianBlur stdDeviation="2.5" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 1 0 0 0 0 0.722826 0 0 0 0 0 0 0 0 0.5 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_22_5658"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_22_5658"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>
          </div>
        </div>
        <div class="form__placeholder--logo">
          <img src="../../assets/img/logo.svg" alt="" />
        </div>
      </div>
    </div>
    <div class="content__page--block" v-if="user">
      <notifications group="foo" position="bottom center" style="color: red" />

      <div class="content__page">
        <!-- content__page--margintop -->

        <div class="container">
          <div class="content__page--back">
            <a @click="$router.go(-1)">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19 12H5"
                  stroke="#0E1839"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12 19L5 12L12 5"
                  stroke="#0E1839"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              {{ $t('system.back') }}
            </a>
          </div>

          <div class="account__edit">
            <div class="content__title--block">
              <div class="content__title section__title">
                <strong>Редактирование данных</strong>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-4 offset-lg-4">
                <div class="form__block--line">
                  <div class="form__block--input">
                    <div class="form__block--title">Имя</div>
                    <input
                      type="text"
                      class="input-linck"
                      :class="[user.name.error ? 'input--error' : '']"
                      required=""
                      name="name"
                      v-model="user.name.value"
                    />
                    <!-- input--error -->
                    <span class="input-required" v-if="user.name.error">{{
                      user.name.error
                    }}</span>
                  </div>
                </div>
                <div class="form__block--line">
                  <div class="form__block--input">
                    <div class="form__block--title">Фамилия</div>
                    <input
                      type="text"
                      class="input-linck"
                      :class="[user.surname.error ? 'input--error' : '']"
                      required=""
                      name="surname"
                      v-model="user.surname.value"
                    />
                    <!-- input--error -->
                    <span class="input-required" v-if="user.surname.error">{{
                      user.surname.error
                    }}</span>
                  </div>
                </div>
                <div class="form__block--line">
                  <div class="form__block--input">
                    <div class="form__block--title">Отчество</div>
                    <input
                      type="text"
                      class="input-linck"
                      :class="[user.patronymic.error ? 'input--error' : '']"
                      required=""
                      name="patronymic"
                      v-model="user.patronymic.value"
                    />
                    <!-- input--error -->
                    <span class="input-required" v-if="user.surname.error">{{
                      user.patronymic.error
                    }}</span>
                  </div>
                </div>
                <div class="form__block--line">
                  <div class="form__block--input">
                    <div class="form__block--title">Телефон <span>*</span></div>
                    <TheMask
                      class="input-linck"
                      required=""
                      :class="[user.phone.error ? 'input--error' : '']"
                      name="phone"
                      :mask="['+7 (###) ### ## ##']"
                      placeholder="+ 7 (_ _ _) _ _ _  _ _  _ _"
                      v-model="user.phone.value"
                    ></TheMask>
                    <!-- input--error -->
                    <span class="input-required" v-if="user.phone.error">{{
                      user.phone.error
                    }}</span>
                  </div>
                </div>
                <div class="form__block--line">
                  <div class="form__block--input">
                    <div class="form__block--title">
                      Наименование организации <span>*</span>
                    </div>
                    <input
                      type="text"
                      class="input-linck"
                      required=""
                      :class="[user.organization.error ? 'input--error' : '']"
                      name="organization"
                      v-model="user.organization.value"
                    />
                    <!-- input--error -->
                    <span
                      class="input-required"
                      v-if="user.organization.error"
                      >{{ user.organization.error }}</span
                    >
                  </div>
                </div>
                <div class="form__block--line">
                  <div class="form__block--input">
                    <div class="form__block--title">Почта <span>*</span></div>
                    <input
                      type="text"
                      class="input-linck"
                      required=""
                      name="surname"
                      :class="[user.email.error ? 'input--error' : '']"
                      v-model="user.email.value"
                    />
                    <!-- input--error -->
                    <span class="input-required" v-if="user.email.error">{{
                      user.email.error
                    }}</span>
                  </div>
                </div>

                <div class="form__block--line">
                  <div class="form__block--input">
                    <div class="form__block--title">
                      Номер удостоверения личности <span>*</span>
                    </div>
                    <input
                      type="text"
                      class="input-linck"
                      required=""
                      name="document_number"
                      :class="[
                        user.document_number.error ? 'input--error' : '',
                      ]"
                      v-model="user.document_number.value"
                    />
                    <!-- input--error -->
                    <span
                      class="input-required"
                      v-if="user.document_number.error"
                      >{{ user.document_number.error }}</span
                    >
                  </div>
                </div>
                <div class="form__block--line">
                  <div class="form__block--input">
                    <div class="form__block--title">
                      Дата выдачи удостоверения личности <span>*</span>
                    </div>
                    <input
                      type="date"
                      class="input-linck"
                      required=""
                      name="date_of_issue_of_the_document"
                      :class="[
                        user.date_of_issue_of_the_document.error
                          ? 'input--error'
                          : '',
                      ]"
                      v-model="user.date_of_issue_of_the_document.value"
                    />
                    <!-- input--error -->
                    <span
                      class="input-required"
                      v-if="user.date_of_issue_of_the_document.error"
                      >{{ user.date_of_issue_of_the_document.error }}</span
                    >
                  </div>
                </div>

                <div class="form__block--line">
                  <div class="form__block--input">
                    <div class="form__block--title">
                      Номер лицензий <span>*</span>
                    </div>
                    <input
                      type="text"
                      class="input-linck"
                      required=""
                      name="surname"
                      :class="[user.license_number.error ? 'input--error' : '']"
                      v-model="user.license_number.value"
                    />
                    <!-- input--error -->
                    <span
                      class="input-required"
                      v-if="user.license_number.error"
                      >{{ user.license_number.error }}</span
                    >
                  </div>
                </div>
                <div class="form__block--line">
                  <div class="form__block--input">
                    <div class="form__block--title">
                      Дата получения лицензии <span>*</span>
                    </div>
                    <input
                      type="date"
                      class="input-linck"
                      required=""
                      name="surname"
                      :class="[
                        user.date_of_receipt_of_the_license.error
                          ? 'input--error'
                          : '',
                      ]"
                      v-model="user.date_of_receipt_of_the_license.value"
                    />
                    <!-- input--error -->
                    <span
                      class="input-required"
                      v-if="user.date_of_receipt_of_the_license.error"
                      >{{ user.date_of_receipt_of_the_license.error }}</span
                    >
                  </div>
                </div>

                <div
                  class="section--link form__listitem--button next__stage--block"
                >
                  <div class="form__listitem--input">
                    <button
                      class="next__stage input__button"
                      @click="onSubmit()"
                    >
                      Изменить
                    </button>
                  </div>
                </div>
                <div
                  class="section--link form__listitem--button next__stage--block"
                  v-if="message"
                >
                  <div
                    style="
                      width: 100%;
                      height: 40px;
                      background-color: #33ad28;
                      color: #fff;
                      line-height: 40px;
                      text-align: center;
                      border-radius: 5px;
                    "
                  >
                    Данные успешно изменены
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { api } from '../../boot/axios'
import { TheMask } from 'vue-the-mask'
import * as Validator from 'validatorjs'

export default {
  name: 'AccountEdit',
  components: {
    TheMask,
  },
  data() {
    return {
      user: {
        load: null,
        id: {
          value: null,
        },
        surname: {
          value: null,
          error: null,
        },
        name: {
          value: null,
          error: null,
        },
        patronymic: {
          value: null,
          error: null,
        },
        phone: {
          value: null,
          error: null,
        },
        email: {
          value: null,
          error: null,
        },
        organization: {
          value: null,
          error: null,
        },
        license_number: {
          value: null,
          error: null,
        },
        date_of_receipt_of_the_license: {
          value: null,
          error: null,
        },
        document_number: {
          value: null,
          error: null,
        },
        date_of_issue_of_the_document: {
          value: null,
          error: null,
        },
      },
      message: null,
    }
  },
  methods: {
    onSubmit() {
      this.user.surname.error = null
      this.user.name.error = null
      this.user.patronymic.error = null
      this.user.phone.error = null
      this.user.email.error = null
      this.user.organization.error = null
      this.user.date_of_receipt_of_the_license.error = null
      this.user.document_number.error = null
      this.user.date_of_issue_of_the_document.error = null

      let rules = {
        'surname.value': 'required|string|max:255',
        'name.value': 'required|string|max:255',
        'patronymic.value': 'string|max:255',
        'phone.value': 'required|string|max:255',
        'email.value': 'required|string|max:255',
        'organization.value': 'required|string|max:255',
        'license_number.value': 'required|string|max:255',
        'date_of_receipt_of_the_license.value': 'required',
        'document_number.value': 'required|string|max:255',
        'date_of_issue_of_the_document.value': 'required|date',
      }

      let messages = {
        'required.surname.value': 'Поле Фамилия обязательно к заполнению.',
        'required.name.value': 'Поле Имя обязательно к заполнению.',
        'required.phone.value': 'Поле Телефон обязательно к заполнению.',
        'required.email.value': 'Поле Почта обязательно к заполнению.',
        'required.organization.value':
          'Поле Наименование организации обязательно к заполнению.',
        'required.license_number.value':
          'Поле Номер лицензий обязательно к заполнению.',
        'required.date_of_receipt_of_the_license.value':
          'Поле Дата получения лицензии  обязательно к заполнению.',
        'required.document_number.value':
          'Поле Номер удостоверения личности обязательно к заполнению.',
        'required.date_of_issue_of_the_document.value':
          'Поле Дата выдачи удостоверения личности обязательно к заполнению.',
      }

      let validation = new Validator(this.user, rules, messages)

      if (validation.fails()) {
        for (let key in validation.errors.errors) {
          if (key == 'surname.value')
            this.user.surname.error = validation.errors.first(key)
          if (key == 'name.value')
            this.user.name.error = validation.errors.first(key)
          if (key == 'phone.value')
            this.user.phone.error = validation.errors.first(key)
          if (key == 'email.value')
            this.user.email.error = validation.errors.first(key)
          if (key == 'organization.value')
            this.user.organization.error = validation.errors.first(key)
          if (key == 'license_number.value')
            this.user.license_number.error = validation.errors.first(key)
          if (key == 'date_of_receipt_of_the_license.value')
            this.user.date_of_receipt_of_the_license.error =
              validation.errors.first(key)
          if (key == 'document_number.value')
            this.user.document_number.error = validation.errors.first(key)
          if (key == 'date_of_issue_of_the_document.value')
            this.user.date_of_issue_of_the_document.error =
              validation.errors.first(key)
        }

        return
      }

      let formData = new FormData()

      formData.append('_method', 'put')
      formData.append('surname', this.user.surname.value)
      formData.append('name', this.user.name.value)
      if (this.user.patronymic.value)
        formData.append('patronymic', this.user.patronymic.value)
      formData.append('phone', this.user.phone.value)
      formData.append('email', this.user.email.value)
      formData.append('organization', this.user.organization.value)
      formData.append('license_number', this.user.license_number.value)
      formData.append(
        'date_of_receipt_of_the_license',
        this.user.date_of_receipt_of_the_license.value,
      )
      formData.append('document_number', this.user.document_number.value)
      formData.append(
        'date_of_issue_of_the_document',
        this.user.date_of_issue_of_the_document.value,
      )

      api
        .post('/users/' + this.user.id.value, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          if (response.data.code == 200) {
            /*
					this.$notify({
						group: 'foo',
						type: 'success',
						title: 'Данные успешно изменены',
						position: 'bottom center'
					});
					*/

            this.message = true

            setTimeout(() => (this.message = null), 2000)
          }
        })
    },
  },
  beforeCreate() {
    if (!localStorage.token) {
      this.$router.push('/ru/login')
    }

    api.get('user').then((response) => {
      if (response.data) {
        this.user.id.value = response.data.data.id
        this.user.surname.value = response.data.data.surname
        this.user.name.value = response.data.data.name
        this.user.patronymic.value = response.data.data.patronymic
        this.user.email.value = response.data.data.email
        this.user.phone.value = response.data.data.phone
          ? '7' + response.data.data.phone
          : null
        this.user.organization.value = response.data.data.organization
        this.user.license_number.value = response.data.data.license_number
        this.user.date_of_receipt_of_the_license.value =
          response.data.data.date_of_receipt_of_the_license
        this.user.document_number.value = response.data.data.document_number
        this.user.date_of_issue_of_the_document.value =
          response.data.data.date_of_issue_of_the_document

        this.user.load = true
      }
    })
  },
}
</script>

<style scoped></style>
